import React from 'react';
import SearchBar from '../../components/Sidebar/SearchBar';
import SidebarCategory from '../../components/Sidebar/SidebarCategory';
import CategoryGrid from '../../components/Category/CategoryGrid';

import NewsItems from '../../components/News/NewsItems'

import BookSidebar from '../../components/Sidebar/BookSidebar';
import TopAuthors from '../../components/Sidebar/TopAuthors';
import SideBarList from '../../components/Sidebar/SideBarList';

import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import ContentLoader from 'react-content-loader'
import BookItemLoader from '../../components/BooksGrid/BookItemLoader';
class NewsList extends React.Component {

    constructor(props) {
        super(props);
        this.state = { newslist: [], Meta: [], loading: true, nexturl: false, pervurl: false };

    }

    componentDidMount() {
        this.newsList();
        console.log(this.newslist);
    }

    componentDidUpdate(prevProps) {

        if (this.props !== prevProps) {
            this.newsList();
            this.backToTop();
            window.scrollTo(0, 0);
        }
    }

    backToTop() {
        if (window.pageYOffset > 0) {
            //let Y = window.pageYOffset - 10;
            window.scrollBy(0, 0);
            //setTimeout(this.backToTop, 0);
        }
    }



    static renderNewsLists(Meta, newslist, nexturl, pervurl) {
        return (
          <>


                <div className="main-content  category-page">


            




                    <div className="content-block padding-zero">
                        <div className="block-wrap">
                            

                          
                            <div className="block">
                                <div className="block-header">
                                    <h2 className="titleHead">Яңалыклар</h2>
                                </div>
                                <div className="tab-wrap module type-book grid">
                                    <div className="tab search">
                                
                                        {newslist.results.map(results =>



                                            <NewsItems key={results.Id} title={results.title} image={results.image} url={results.url} publicDate={results.publicDate} />



                                        )
                                        }

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="foot-block">
                        <div className="text-center">


                            {pervurl ? (

                                <Link to={newslist.previousUrl}> <div className="btnP btn">Элек </div></Link>

                            ) : (
                                    <div>

                                    </div>
                                )
                            }



                            {nexturl ? (

                                <Link to={newslist.nextUrl}> <div className="btnN btn">Далее </div></Link>

                            ) : (
                                    <div>

                                    </div>
                                )
                            }





                        </div>
                    </div>





                </div>
        </>


        )
    };





    render() {
        let contents = this.state.loading
            ? <>



                <div className="main-content  category-page">


                    <h1 className="inline-title"> </h1>

                    <div className="content-block">
                        <div className="block-wrap">
                            <div className="block">
                                <div className="block-header">
                                    <h2 className="titleHead">Яңалыклар</h2>
                                </div>

                                <div className="tab-wrap module type-book grid">
                                    <div className="tab search">
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
          </>
            : NewsList.renderNewsLists(this.state.Meta, this.state.newslist, this.state.nexturl, this.state.pervurl);

        return (
            <div>

              
                {contents}
            </div>
        );
    }


    async newsList() {

        const id = this.props.match.params.id;
        console.log(this.props.match.params.id);



        const search = this.props.location.search; // 
        const params = new URLSearchParams(search);
        let pg = params.get('PageNumber'); // 

        console.log(pg);
        if (pg == null) {
            pg = 1;
        }

        const response = await fetch('https://tatkniga.ru/api/postlist?LanguageId=1' + '&PageNumber=' + pg);

        const meta = JSON.parse(response.headers.get('x-pagination'));


        const d = await response.json();

        console.log(d);


        this.setState({ newslist: d, Meta: meta, loading: false, nexturl: d.nextUrl, pervurl: d.HasPrevious });
        


    }

}

export default NewsList;













