import React, { useContext, useEffect} from 'react';
import Brcrumb from '../../components/Book/Brcrumb';
import BookItemBlock from '../../components/Book/BookItemBlock';
import BookDetails from '../../components/Book/BookDetails';
import PropTypes from 'prop-types';
import SearchBar from '../../components/Sidebar/SearchBar';
import SidebarCategory from '../../components/Sidebar/SidebarCategory';
import CategoryGrid from '../../components/Category/CategoryGrid';
import BookItem from '../../components/BooksGrid/BookItem';
import BookSidebar from '../../components/Sidebar/BookSidebar';
import TopAuthors from '../../components/Sidebar/TopAuthors';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import SideBarList from '../../components/Sidebar/SideBarList';

export const About = () => {


    useEffect(() => {
        window.scrollTo(0, 0);

    })


    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
    

            <div className="main-content  category-page">


                <div className="content-block padding-zero">
                    <div className="block-wrap">
                        <div className="block">

                            <div className="tab-wrap module type-book grid">

                                <div className="col-xs-12 newstext ">
                                    <h3>
                                        <div
                                            className="item-excerpt trunc" >
                                        </div>
                                    </h3>

                                    <div
                                        className="item-excerpt trunc article-body" >

                                    <div>
                                        <p> «Татар әдәбияты үзәге» проектын гамәлгә куючы – ТР ДУП «Татарстан китап нәшрияты» – Россия Федерациясендә бер гасырлык тарихы булган иң зур милли нәшриятларның берсе (1919 елда нигез салынган).<br/>
                                            Татарстан китап нәшрияты генераль директоры – Сәгъдәтшин Илдар Камил улы.<br />
                                            Татарстан китап нәшрияты баш мөхәррире – Шәехов Ленар Миңнемөһим улы.<br />
                                            Татар әдәбиятын Татарстан Республикасында гына түгел, Россиянең башка төбәкләрендә дә тарату, шулай ук халыкара аудиторияне җәлеп итү өчен, Татарстан китап нәшрияты «Татар әдәбияты үзәге» проектын тормышка ашыруга кереште.
                                            «Татар әдәбияты үзәге» порталы үзендә интернет-кибет һәм бушлай онлайн-китапханә функциясен берләштерде. Моннан кала, бушлай аудиокитаплар тыңлау һәм аларны күчереп алу мөмкинлеге дә бар.
                                            Порталда татар әдәбиятына мөнәсәбәтле төрле жанрдагы иҗат үрнәкләре урнаштырыла.</p>

                                            <p><h3>«Татарстан китап нәшрияты» турында.</h3>
                                            Нәшриятның барлыкка килүе һәм үсеше Галимҗан Ибраһимов, Муса Җәлил, Фатих Кәрим, Гомәр Бәширов, Шәүкәт Галиев, Рәис Даутов кебек талантлы татар зыялыларының исемнәре белән тыгыз бәйләнгән.
                                            Бүгенге көндә Татарстан китап нәшриятында:</p>
                                        <ul>
                                            <li>югары квалификацияле белгечләр коллективы эшли;</li>
                                            <li>ел саен татар, рус һәм инглиз телләрендә, шулай ук Татарстан Республикасында яшәүче башка халыклар телләрендә 200 дән артык исемдәге китап чыгарыла;</li>
                                            <li>төрле яшьтәге китап сөючеләр өчен киң тематик эчтәлекле китаплар нәшер ителә: матур әдәбият һәм балалар әдәбияты, тарихи һәм фәнни-популяр басмалар, дәреслекләр һәм уку әсбаплары, сүзлекләр һәм белешмәлекләр, бүләк һәм сувенир басмалар, альбомнар һ.б. нәшер ителә.</li>
                                            <li>хәзерге заман китап укучысы ихтыяҗын истә тотып, китапларны басма форматта гына түгел, электрон һәм аудио форматта укырга, тыңларга мөмкин.</li>
                                            </ul>
                                                <p>Нәшрият китапны басмага әзерләүнең бөтен төр юнәлештәге эшләрен – кулъязмаларны әдәби редакцияләүдән башлап, автор һәм рәссамнар белән киңәшләшеп, оригинал-макет әзерләүгә кадәр башкара. Төрле профильдәге басмалар нәшер итә. Нәшриятның фирма кибетләре Татарстан Республикасы гына түгел, башка төбәкләрнең дә китап сату челтәрләре аша ваклап һәм күпләп сатуны оештыра.
                                            Нәшриятта балалар китапларына һәм аларның сәнгатьле бизәләшенә аерым игътибар бирелә. Тематик планда каралган үзенчәлекле китаплар, альбомнар һәм сувенир басмалар һәрвакыт китап сөючеләр тарафыннан җылы кабул ителә һәм көтеп алына.
                                            Китапларның күп өлеше татар телендә нәшер ителә. Шул ук вакытта Татарстан китап нәшрияты татар халкының гына түгел, республикада яшәүче башка халыкларның да мәнфәгатьләрен киң һәм тирән чагылдырырга омтыла.
                                            Татарстан Республикасында социаль әһәмияткә ия әдәбият бастыруга зур игътибар бирелә. Соңгы елларда Татарстан Республикасы Президенты ярдәме белән Габдулла Тукай, Гаяз Исхакый, Һади Такташ, Галиәсгар Камал, Нәкый Исәнбәт, Муса Җәлил, Әмирхан Еники, Шәүкәт Галиев, Аяз Гыйләҗев, Туфан Миңнуллин, Әхсән Баян, Илдар Юзеев һ. б. күренекле язучыларыбызның 40 комплекттан артык күптомлыклары дөнья күрде.
                                            Нәшрият коллективының нәтиҗәле эшчәнлеге нәтиҗәсендә, күп кенә язучылар һәм шагыйрьләр, публицистлар һәм рәссамнар, фән, мәдәният һәм мәгариф эшлеклеләре, табиблар һәм артистлар, дәүләт һәм җәмәгать эшлеклеләре исемнәре киң даирә укучыларга мәгълүм булды.
                                            Татарстан китап нәшрияты коллективының югары профессиональлеге һәм уртак иҗади тырышлыгы Татарстан китап нәшриятына үз йөзен саклап калырга, җитештерүчәнлекне арттырырга һәм сыйфатны яхшыртырга ярдәм итте. Нәшрият безнең илдә генә түгел, дөньякүләм танылуга да иреште – ел саен Татарстан китап нәшриятының басмалары Россия һәм халыкара дәрәҗәле әдәби конкурсларда җиңү яулый.
                                            </p>

                                            <p><h3>Татарстан китап нәшриятының барлыкка килү тарихы</h3>
                                            Татарстан китап нәшриятының барлыкка килүе Казан шәһәренең күпсанлы шәхси, кооператив һәм ведомство нәшриятларының һәм типографияләренең үзәкләштерелүе һәм дәүләт карамагына күчерелүе белән бәйле. 1919 елның язында Үзәк татар-башкорт комиссариаты, китап басу һәм татар-мөселманнар арасында милли-агарту эшен җайга салу максатыннан, матбугат бүлеген Мәскәүдән Казанга күчерергә карар итә (матбугат бүлеге мөдире — татар әдәбияты классигы Галимҗан Ибраһимов була). 1919 елның 22 июлендә РКП (б)ның Казан губкомы Карары нигезендә РСФСР Дәүләт нәшриятының Казан бүлеге оештырыла, һәм бу дата Татарстан китап нәшриятының туган көне буларак тарихка кереп калды.
                                            1920 елда Татарстан Автономияле Республикасы оешу республикада икътисад, мәдәният һәм иҗтимагый тормышның күп тармакларына көчле этәргеч бирә. Үзенең статусын үзгәртеп, нәшрият Татарстан дәүләт нәшрияты (Татгосиздат) булып оеша һәм республикадагы барлык нәширлек процессын үз тирәсенә берләштерә. РКП (б) Татар обкомының 1922 елның 30 ноябрь Карары белән Татар дәүләт нәшрияты (Татгосиздат) «Татпечать» тресты белән берләштерелә, һәм алар базасында Татар нәшрият һәм матбугат комбинаты оештырыла.
                                            Оешкан вакытыннан бирле нәшриятның исеме берничә тапкыр үзгәртелә: 1925 елдан – Татар дәүләт нәшрияты (Татгиз), 1927 елдан – Татар нәшрияты (Татиздат), 1933 елдан, К. Якуб исемендәге полиграфия комбинаты белән берләшкәннән соң, – Татар дәүләт нәшрияты (Татгосиздат), 1954–1958 елларда – Татар дәүләт нәшрияты (Таткнигоиздат). 1958 елда, «Татполиграф» һәм «Таткниготорг» аерылып чыкканнан соң, нәшрият хәзерге исемен ала. Оешкан вакытыннан башлап Татарстан китап нәшрияты татар телендә әдәби, фәнни-популяр һәм иҗтимагый-сәяси әдәбиятны дөньяга чыгаручы иң зур нәшрият булып тора.<br />
                                            1963 елда Татарстан китап нәшрияты тарихында беренче тапкыр 1006 исемдә китап басыла, шуларның 593е – татар телендә була.<br />
                                            1980 еллар – Татарстан китап нәшрияты үсешендә чын мәгънәсендә алтын чор. Һәр елны 350 исемдә китап басыла, аларның гомуми тиражы 20 миллион данә тәшкил итә.<br />
                                            1926–1935 елларда нәшрият Кремль урамында Пассаж бинасының беренче катында урнаша. 1935 елда архитектор С. С. Пэн проекты буенча нәшрият өчен Бауман урамында яңа бина салына; халык теленә ул Матбугат йорты дип керә. Әлеге бина баштан ук нәшрият  һәм полиграфия үзәге итеп планлаштырыла. 2009 елдан Татарстан китап нәшрияты "Татмедиа» АҖ филиалы «Идел-Пресс" полиграфия-нәшрият комплексы бинасында эшли.
                                            Нәшрият тарихы Г.Ибраһимов, В. Шәфигуллин, Г. Камал, Г. Нигъмәти, Г. Толымбай, Ф. Борнаш, Ф. Сәйфи-Казанлы, И. Рәми кебек талантлы татар зыялыларының исемнәре белән тыгыз бәйләнгән. Аларга алмашка Н. Баян, Ф. Кәрим, А. Шамов, Г. Бәширов, С. Хәким, М. Садри һ. б. киләләр. Совет дәүләте оешуның беренче елларында, Бөек Ватан сугышы, сугыштан соңгы чорда әдәбиятка бик күп яңа авторлар килә. Шуның нәтиҗәсендә галимнәргә фәнни-популяр тикшеренүләргә юл ачыла, яңа китаплар дөнья күрә.
                                            Иҗат берлекләре, фәнни үзәкләр, министрлыклар белән тыгыз элемтәдә тору нәшрият коллективына ел саен рус һәм татар телләрендә йөзләгән төрле исемдә китап чыгару мөмкинлеге бирде: матур әдәбият һәм балалар әдәбияты; дәреслекләр һәм уку әсбаплары; сүзлекләр һәм белешмәлекләр; ноталы басмалар; әдәби-сәнгать җыентыклары; антологияләр; туган якны өйрәнү, әдәбият белеме, халык иҗаты һәм башка белем тармаклары буенча күпсанлы басмалар дөнья күрде.
                                            </p>
                                    

                                       
                                    </div>


                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
     
        
        
        )
}