import React, {useEffect, useState} from 'react';
import StoreChild from '../../components/BooksGrid/StoreChild';
import StorePub from '../../components/BooksGrid/StorePub';
import StoreTatLesson from '../../components/BooksGrid/StoreTatLesson';

import BookSidebar from '../../components/Sidebar/BookSidebar';
import TopAuthors from '../../components/Sidebar/TopAuthors';

import StoreCatList from '../../components/Category/StoreCatList';
import { Helmet } from "react-helmet";
import OkStatus from "../../components/IconStatus/OkStatus";
import ErrStatus from "../../components/IconStatus/ErrStatus";
import { Nav } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

import Loader from 'react-loader-spinner';

function YkassaCheck(props) {
    const [loader, setLoader] = useState("LoaderViews");
    const [result, setResult] = useState({ "status": 0, "confirmation_url":null});


    useEffect(() => {
        if (result["status"] == 0) {
            
            LoadSatus(props)
        }
    })


    async function LoadSatus(props) {

       
       
        const InvoiceGuid = props.match.params.id; // 
        const url = 'https://tatkniga.ru/api/YkassaCheck?InvoiceGuid=' + InvoiceGuid;
   
        const response = await fetch(url);
        const data = await response.json();
        console.log(data)
        setResult(data);
        setLoader("dispnone")
    

}

    return (

        <div className="container-fluid pay">

            <Helmet>
                <title>Таткнига - Платеж</title>
                <meta name="description" content="Платеж  " />
            </Helmet>

            <div class="mainbox">
            <div className={loader}>

                    <Loader className="loaderIcons"
                        type="Bars"
                        color="#08a201"
                        height={100}
                        width={100}
                    />
            </div>

      

            {result.status === 100 &&
            <div className="errorCheck">
                <ErrStatus />
                    <p>Ошибка. Попробуйте обновить или повторить платеж</p>
                    <p><Nav.Link as={Link} to="/myorders" className="btn" > Мои заказы </Nav.Link> </p>
                    <p><a className="btn" href={result.confirmation_url}> Повторить платеж </a> </p>
                </div>
                
            
            }


            {result.status === 500 &&
                <div className="errorCheck">
                    <ErrStatus />
                    <p>Ошибка. Попробуйте обновить страницу</p>
                    <p><Nav.Link as={Link} to="/myorders" className="btn" > Мои заказы </Nav.Link> </p>
                    
                </div>


            }
            {result.status === 200 &&

                <div className="OkCheck">
                    <OkStatus />
                    <p>Платёж успешно проведен</p>
                    <p><Nav.Link as={Link} to="/myorders" className="btn" > Мои заказы </Nav.Link> </p>
                </div>

            }


      
            </div>
        </div>
        
        
        
        )

}

export default YkassaCheck;