import React from 'react';

import { Link } from "react-router-dom";
import './404.css';
import { Helmet } from "react-helmet";

const NotFoundPage = () => {
    return (

        <>
          <Helmet>
           <title>Таткнига - 404 ошибка</title>
          <meta name="description" content="Ошибка 404! Эта страница не найдена! " />
         </Helmet>

            <div class="mainbox">
                
                
                <div class="err">404</div>
                <div class="msg">Бу бит табылмады!  <p>Баш биткә <strong><Link to="/">кайту</Link></strong> </p></div>
            

            </div>
          </>
    );
}

export default NotFoundPage;
