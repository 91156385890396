import React, { Component, useContext, useState, useRef, useEffect } from 'react';
import { useForm } from "react-hook-form";
import ReactDadataBox, { PartyResponseType } from "react-dadata-box";
import { CartContext } from '../../contexts/CartContext';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import authService from '../../components/api-authorization/AuthorizeService';
import useScript from '../Checkout/useScript';
import { Link } from "react-router-dom";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Col } from 'react-bootstrap';

const Token = "bc1e08252bb6e427d906201f63c565bcc242c0e3";





const Checkout = (props) => {



    const { register, handleSubmit, watch, errors } = useForm();

    const [address, setAddress] = useState({});
    const [loadaddress, setLoadAddress] = useState("loadDisp");

    const [countryId, setCountryId] = useState();
    const [city, setCity] = useState();
    const [region, setRegion] = useState();
    const [otherDeliveryValue, setOtherDeliveryValue] = useState();
    const [house, setHouse] = useState();
    const [street, setStreet] = useState();
    const [flat, setFlat] = useState();
    const [postalCode, setPostalCode] = useState();
    const [addressFullView, setAddressFullView] = useState("loadDisp");
    const [addressDadata, setAddressDadata] = useState("loadDisp");
    const [addressOther, setAddressOther] = useState("loadDisp");
    const [dispShipDataLoad, setDispShipDataLoad] = useState("loadDisp");
    const [dispShipDataView, setDispShipDataView] = useState("viewDisp");

    const [deliveryOtherType, setDeliveryOtherType] = useState("loadDisp");
    const [selectShip, setSelectShip] = useState();
    const [selectDeliveryTypeId, setSelectDeliveryTypeId] = useState();
    const [phone, setPhone] = useState();
    const [dateDelivery, setDateDelivery] = useState();
    const [btnName, setBtnName] = useState("Оплатить")
    const { total, cartItems, itemCount, clearCart, checkout, handleCheckout } = useContext(CartContext);
    const [delivery, setDelvery] = useState("loadDisp");

    const [allType, setAllType] = useState("loadDisp");


    const [payDisp, setPayDisp] = useState("loadDisp");







    const [loader, setLoader] = useState("loaderNone");

    const [payloader, setPayloader] = useState("loaderNone");


    const [err, setErr] = useState("errNone");


    const [helpData, setHelpData] = useState("");


    const [deliveryType, setDeliveryType] = useState();
    const [paymentType, setPaymentType] = useState();
    const [totalPrc, setTotalprc] = useState(total);
    const [shipPrc, setShipprc] = useState(0);
    const [isLoadCountries, setisLoadCountries] = useState(false);
    const [shipping, setShipping] = useState();
    const [pay, setPay] = useState();
    const [loaderDelivery, setLoaderDelivery] = useState("loadDisp");
    const [payUrl, setPayUrl] = useState("/checkout");
    const [payView, setPayView] = useState("loadDisp");
    const [orderDataView, setOrderDataView] = useState("viewDisp");
    const [countries, setCountries] = useState();



    useScript('https://tatkniga.ru/assets/dasgst.js');


    useEffect(() => {

        document.title = `Оформление заказа`;



        if (isLoadCountries === false) {

            LoadCountries();

            setisLoadCountries(true);

        }
    })


    async function LoadCountries() {
        const token = await authService.getAccessToken();
        const headersReq = new Headers();

        headersReq.append('Content-Type', 'application/json');
        headersReq.append('Authorization', `Bearer ${token}`);
        const url = `https://tatkniga.ru/api/GetCountries`;

        const res = await
            fetch(url, {
                method: 'get',
                headers: headersReq,

            }).then(response => {
                if (response.status == 200) {
                    return response;
                } if (response.status == 401) {

                    authService.signOut();
                }
            });
        const data = await res.json();

        setCountries(data);

    }


    async function handleCountryChange(event) {


        setCountryId(event.target.value);
        setPayDisp("loadDisp");



        setDispShipDataLoad("loadDisp");

        if (event.target.value !== '252' && event.target.value !== '0') {


            setPostalCode("");
            setFlat("");;
            setStreet("");
            setHouse("");
            setRegion("");
            setCity("");
            setAddressFullView('viewDisp');
            setAddressDadata('loadDisp');

            setAddressOther('viewDisp');
            setDeliveryType();
            setLoaderDelivery('viewDisp');

            LoadDelivery(postalCode, event.target.value);


        }
        if (event.target.value == '252') {
            setDeliveryType();
            setAddressOther('loadDisp');
            setAddressFullView('loadDisp');

            setAddressDadata('viewDisp');
        }


    }

    const onSubmit = (data) => {

        createOrder(data);





    };
    async function SignOutCheck() {
        authService.signOut()
    }
    async function LoadDelivery(postal_code, _countryId) {
        let datas = {
            "data": cartItems,
            "postal_code": postal_code,
            "countryId": _countryId
        }

        const token = await authService.getAccessToken()
        const headersReq = new Headers();

        headersReq.append('Content-Type', 'application/json');
        headersReq.append('Authorization', `Bearer ${token}`);
        const res = await
            fetch('https://tatkniga.ru/api/GetDelivery', {
                method: 'post',
                headers: headersReq,
                body: JSON.stringify(datas)
            }).then(response => {
                if (response.status == 200) {
                    setLoaderDelivery('loadDisp');
                    return response;
                } if (response.status == 401) {

                    SignOutCheck()
                }
            });

        let body = await res.json();

        setDeliveryType(body);
        setDispShipDataLoad("viewDisp");
    }

    async function createOrder(data) {


        let datas = {
            "data": data,
            "items": cartItems,
            "deliveryPrice": shipPrc,
            "ShippingMethodId": selectShip,
            "ShippingMethods": selectDeliveryTypeId


        }


        setLoader("loaderView")

        const token = await authService.getAccessToken()


        const headersReq = new Headers();

        headersReq.append('Content-Type', 'application/json');
        headersReq.append('Authorization', `Bearer ${token}`);


        const res = await
            fetch('https://tatkniga.ru/api/CreateTatOrder', {
                method: 'post',
                headers: headersReq,

                body: JSON.stringify(datas)
            });

        let d = await res.json()

        if (d['type'] != "Error") {

            setLoader("loaderNone")


            setPayUrl(d['confirmation_url']);
            setOrderDataView("loadDisp");
            setPayView("viewDisp");
            window.scrollTo(0, 0);
            clearCart();
            window.location.href = d['confirmation_url'];

            return false;

        }
        else {
            setLoader("loaderNone");
            setErr("errData");
        }



    }
    async function handleDeliveryChange(event) {

        let p = totalPrc - shipPrc;
        setSelectShip(0);
        setShipprc(0);
        setTotalprc(p);
        const id = event.target.value;

        setSelectDeliveryTypeId(event.target.value);


        setPaymentType();
        setPayDisp('loadDisp');

        const res = JSON.parse(JSON.stringify(deliveryType));

        if (event.target.value == 6) {
            setAllType("loadDisp")
            setOtherDeliveryValue("")
            setDeliveryOtherType("viewDisp")
            setSelectShip(event.target.value.toString());
            LoadPayment(event.target.value.toString());

            setShipprc(0);
        }
        else {
            setAllType("viewDisp")
            setOtherDeliveryValue("null")
            setDeliveryOtherType("loadDisp")
        }
        for (var i in res) {
            if (res[i].deliveryTypeId == id) {
                res[i].className = "viewDisp";
            }
            else {
                res[i].className = "loadDisp";
            }

        }


        setDeliveryType(res);






    }

    function changeType(suggestion) {


        setPayDisp("loadDisp");
        //setAddress(suggestion);
        console.log(suggestion);
        let infoData = "";
        if (suggestion.value.length < 3) {


            setAddressOther('loadDisp');
            infoData = 'Введите Ваш адрес'
            setHelpData(infoData);
            setPostalCode("");
            setFlat("");;
            setStreet("");
            setHouse("");
            setRegion("");
            setCity("");
        }
        else {
            infoData = '';
        }


        if (suggestion.data.city === null) {

            setCity(suggestion.data.settlement_with_type);

        }
        else {
            if (suggestion.data.city_with_type != null) {
                if (suggestion.data.settlement_with_type != null) {
                    let cty = suggestion.data.city_with_type + ", " + suggestion.data.settlement_with_type;
                    if (suggestion.data.settlement_type_full != null && suggestion.data.settlement_type_full != null) {
                        let cty = suggestion.data.city_with_type + ", " + suggestion.data.settlement_type_full + " " + suggestion.data.settlement;
                    }


                    setCity(cty);
                }
                else {
                    setCity(suggestion.data.city_with_type);
                }
            }


        }


        if (suggestion.data.area_with_type === null) {

            setRegion(suggestion.data.region_with_type);
        }
        else {
            if (suggestion.data.area_with_type != null) {
                let r = suggestion.data.region_with_type + ", " + suggestion.data.area_with_type;
                setRegion(r);
            }
            else {
                setRegion("");
            }
        }

        if (suggestion.data.house_type != null) {

            let r = suggestion.data.house;
            if (suggestion.data.block_type != null) {
                r = r + " " + suggestion.data.block_type + " " + suggestion.data.block;
            }

            setHouse(r);
        }
        else {
            setHouse("");
        }

        if (suggestion.data.postal_code != undefined || suggestion.data.postal_code != null) {
            if (suggestion.data.street_with_type != null) {

                setPostalCode(suggestion.data.postal_code);
                setLoaderDelivery('viewDisp');
                LoadDelivery(suggestion.data.postal_code, countryId);
            }

        }
        else {
            setPostalCode("");
            setLoaderDelivery('loadDisp');


        }
        if (suggestion.data.street_with_type != null) {
            setAddressOther('viewDisp');
            setStreet(suggestion.data.street_with_type);
            infoData = "";
            setHelpData(infoData);
        }
        else {
            setAddressOther('loadDisp');

            if (infoData != 'Адресны языгыз, ') {
                infoData = 'Урам исемен языгыз';
                setHelpData(infoData);
            }

            setDispShipDataLoad('loadDisp')
            setStreet('');
            setLoaderDelivery('loadDisp');
        }
        if (suggestion.data.flat != null) {

            setFlat(suggestion.data.flat)
        }

    }

    function handlePostalCode(event) {

        setPostalCode(event.target.value.trim());
        setPayDisp('loadDisp')

        if (countryId == 252 && event.target.value.length == 6) {

            setDispShipDataLoad('loadDisp')
            setLoaderDelivery('loadDisp');
            setDeliveryType();

            setLoaderDelivery('viewDisp');
            LoadDelivery(event.target.value, countryId);
        }
        else {
            if (countryId == 252) {

                setDispShipDataLoad('loadDisp')
            }
        }


    }


    async function handleDeliverySelectChange(event) {

        setSelectShip(event.target.value);
        setBtnName("Оплатить");
        if (event.target.value == 6) {
            setDeliveryOtherType("viewDisp");
        }
        else {
            setDeliveryOtherType("loadDisp");
        }

        if (event.target.value == 3) {
            setBtnName("Заказать");
        }
        else {
            setBtnName("Оплатить");
        }

        //let t = (Math.round(total) + Math.round(ps)).toFixed(2);
        deliveryType.forEach(dts => {
            dts.deliveryTypeListName.forEach(dt => {
                if (dt.deliveryId == event.target.value) {

                    let ps = dt.deliveryPrice.toFixed(2);
                    setShipprc(ps);
                    let t = (Math.round(total) + Math.round(ps)).toFixed(2);
                    setTotalprc(t);
                    setDateDelivery(dt.deliveryTime);

                    LoadPayment(event.target.value);
                }
            });
        });

        //console.log(t);




    }

    async function LoadPayment(id) {
        const response = await fetch('https://tatkniga.ru/api/GetPayment?id=' + id);

        const data = await response.json()

        setPaymentType(data);
        console.log(data);
        setPayDisp("viewDisp");

    }


    return (
        <div>


            <div className="content-wrap">
                <div className="main-content checkout-page payment-step checkout-homer address-validation iframe-enabled no-delivery-country-selected ">
                    <ol className="steps">
                        <li className="complete">Заказ детальләре</li>
                        <li>Түләү</li>
                    </ol>





                    <div className={`block-wrap content-block existing-customer-block ${orderDataView}`} >
                        <div className="row block-wrap-content">

                            <div className={err}>
                                Хата.  support@tatkniga.ru
                            </div>




                            <form onSubmit={handleSubmit(onSubmit)}>

                                <div className="col-xs-12">
                                    <div className="form-group address-form-col">

                                        <label className="control-label" >
                                            ФИО
                                        </label>
                                        <input name="FullName" className="form-control" placeholder="Введите Ваше ФИО" ref={register({
                                            required: true, maxLength: 500,
                                            pattern: {

                                                value: /^[a-zA-Z\u0400-\u04ff ,.'-]{2,}\s[a-zA-Z\u0400-\u04ff ,.'-]+$/u,
                                                message: "Введите ФИО"

                                            }
                                        })} />

                                    </div>
                                </div>



                                <div className="col-xs-12">
                                    <div className="form-group address-form-col">

                                        <label className="control-label" >
                                            Телефон
                                        </label>
                                        <PhoneInput
                                            className="form-control"
                                            placeholder="Введите номер телефона"
                                            value={phone}
                                            onChange={setPhone} />
                                    </div>
                                </div>

                                <div className="col-xs-12 loadDisp">
                                    <div className="form-group address-form-col">

                                        <label className="control-label" >
                                            Телефон
                                        </label>
                                        <input name="Phone" className="form-control" value={phone} ref={register({ required: true, maxLength: 350 })} />

                                    </div>
                                </div>

                                <div className="col-xs-12">
                                    <div className="form-group address-form-col">

                                        <label className="control-label" >
                                            Сайлагыз илне
                                        </label>
                                        <select className="form-control" onChange={(event) => handleCountryChange(event)} name="CountryId" ref={register({ required: true })} >
                                            <option value="0" > Выберите страну</option>
                                            {countries ? (countries.map(country =>
                                                <option key={country.countriesId} value={country.countriesId}>{country.country}</option>
                                            )
                                            ) : (<option>ошибка</option>)

                                            }
                                        </select>

                                    </div>
                                </div>

                                <div className={addressDadata}>
                                    <div className="col-xs-12 ">
                                        <div className="form-group address-form-col">
                                            <label className="control-label">
                                                Введите адрес доставки (город/поселок, улица, дом, квартира)
                                            </label>
                                            <ReactDadataBox
                                                token={Token}


                                                onChange={(changeType)}

                                            />

                                            <span className="redColorText">{helpData}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className={addressFullView}>
                                    <div className="col-xs-12">
                                        <div className="form-group address-form-col">

                                            <label className="control-label">
                                                Регион
                                            </label>
                                            <input name="Region" value={region} className="form-control" onChange={e => setRegion(e.target.value)} ref={register({ required: true, maxLength: 500 })} />

                                        </div>
                                    </div>

                                    <div className="col-xs-12">
                                        <div className="form-group address-form-col">

                                            <label className="control-label" >
                                                Шәһәр/бистә
                                            </label>
                                            <input name="City" className="form-control" value={city} onChange={e => setCity(e.target.value)} ref={register({ required: true, minLength: 2, maxLength: 500 })} />

                                        </div>
                                    </div>



                                    <div className="col-xs-12">
                                        <div className="form-group address-form-col">

                                            <label className="control-label" >
                                                Урам
                                            </label>
                                            <input name="Street" className="form-control " value={street} onChange={e => setStreet(e.target.value)} ref={register({ required: true, minLength: 2, maxLength: 500 })} />

                                        </div>
                                    </div>

                                </div>


                                <div className={addressOther}>

                                    <div className="col-xs-6">
                                        <div className="form-group address-form-col">

                                            <label className="control-label" >
                                                Йорт
                                            </label>
                                            <input name="House" className="form-control " value={house} onChange={e => setHouse(e.target.value)} ref={register({ required: true, minLength: 1, maxLength: 50 })} />

                                        </div>
                                    </div>
                                    <div className="col-xs-6">
                                        <div className="form-group address-form-col">

                                            <label className="control-label" >
                                                Фатир
                                            </label>
                                            <input name="Flat" className="form-control " value={flat} onChange={e => setFlat(e.target.value)} ref={register({ required: false, minLength: 1, maxLength: 50 })} />

                                        </div>
                                    </div>

                                    <div className="col-xs-12">
                                        <div className="form-group address-form-col">

                                            <label className="control-label" >
                                                Индекс
                                            </label>
                                            <input name="PostalCode" value={postalCode} onChange={event => handlePostalCode(event)} className="form-control " ref={register({ required: true, minLength: 2, maxLength: 350 })} />

                                        </div>
                                    </div>

                                </div>

                                <div className="col-xs-12">
                                    <div className="form-group address-form-col">

                                        <label className="control-label" >
                                            Хәбәр
                                        </label>
                                        <textarea name="Description" className="form-control" ref={register({ required: false, maxLength: 500 })} />

                                    </div>
                                </div>
                                <div className={loaderDelivery}>
                                    <div className="loader-frame">
                                        <Loader className="loaderIcon"
                                            type="Bars"
                                            color="#088b01"
                                            height={100}
                                            width={100}


                                        /><h3>Китерү...</h3>
                                    </div>

                                </div>
                                <div className={dispShipDataLoad}>
                                    <div className="col-xs-12">

                                        <div className="form-group address-form-col shippingmethod">
                                            <h3 className="checkout-head">Китерү  </h3>
                                            <fieldset className="schedule-weekday" >
                                                {deliveryType ? (deliveryType.map((data, idx) =>
                                                    <div key={idx} className="typeship delivery" >
                                                        <label>
                                                            <input value={data.deliveryTypeId} name="ShippingMethods" type="radio" onChange={(event) => handleDeliveryChange(event)} /> <img src={`https://tatkniga.ru/img/${data.deliveryIcon}`} /><span>{data.name}</span>
                                                            {data.deliveryPrice != 0 ?
                                                                (<p className="priceview"> от {data.deliveryPrice} руб.</p>)
                                                                : (<> </>)
                                                            }
                                                        </label>

                                                        {data.deliveryTypeId === 6 &&

                                                            <div className={deliveryOtherType}>
                                                                <div className="col-xs-12">
                                                                    <div className="form-group address-form-col">

                                                                        <label className="control-label" >

                                                                        </label>
                                                                        <h3 className="checkout-text-my">Вы можете указать свой способ доставки - курьерскую службу/ транспортную компанию.  В  этом случае Вы сами делаете  заказ  курьерской службе/транспортной компании и  стоимость  доставки  оплачиваете самостоятельно).</h3>
                                                                        <textarea name="DeliveryOtherType" className="form-control checkout-my" placeholder="Укажите свой вариант (транспортную компанию, службу доставки и т.д.)." ref={register({ required: true, maxLength: 500 })} value={otherDeliveryValue}
                                                                            onChange={e => setOtherDeliveryValue(e.target.value)}
                                                                        />

                                                                    </div>
                                                                </div>
                                                            </div>

                                                        }



                                                        <div className={allType}>
                                                            <div className={data.className}>
                                                                <select className="form-control" value={selectShip} name="ShippingMethodId" onChange={(event) => handleDeliverySelectChange(event)}>
                                                                    <option value="0" > Сайлагыз </option>
                                                                    {data.deliveryTypeListName ? (data.deliveryTypeListName.map((item, index) =>
                                                                        <option key={index} value={item.deliveryId}>{item.deliveryName}</option>
                                                                    )
                                                                    ) : (<option>ошибка</option>)

                                                                    }
                                                                </select>

                                                            </div>
                                                        </div>

                                                        <div className={data.className}>

                                                            {shipPrc != 0 ? (
                                                                <div>
                                                                    <p>Cрок доставки в днях: {dateDelivery}</p>
                                                                    <p>Бәя: {shipPrc}</p>
                                                                    <hr />
                                                                </div>
                                                            )
                                                                : (<> </>)
                                                            }
                                                        </div>







                                                    </div>

                                                )


                                                ) :
                                                    (<div>Адресны үзгәртегез</div>)
                                                }
                                            </fieldset >



                                        </div>
                                    </div>

                                </div>



                                <div className="col-xs-12">
                                    <div className="linegreen" />
                                </div>



                                <div className={payDisp}>
                                    <div className="col-xs-12">
                                        <div className="form-group address-form-col paymenttype">
                                            <h3 className="checkout-head">Способы оплаты</h3>
                                            {paymentType ? (paymentType.map((data, idx) =>
                                                <div key={data.paymentTypeId} className="typeship">
                                                    <fieldset > <label>
                                                        {data.paymentTypeId == 1 ? (
                                                            <>
                                                                <input name="PaymentTypes" id="paymentType" type="radio" className="paychecked" ref={register({ required: true })} checked value={data.paymentTypeId} />{data.nameRus}
                                                            </>
                                                        ) : (<>
                                                            <input name="PaymentTypes" id="paymentType" type="radio" ref={register({ required: true })} value={data.paymentTypeId} /> <span>{data.nameRus} </span>
                                                        </>
                                                        )}
                                                    </label>
                                                    </fieldset >
                                                </div>

                                            )


                                            ) :
                                                (<div>Түләү мөмкин түгел</div>)
                                            }



                                        </div>


                                    </div>


                                    <div className="col-xs-12">
                                        <div className="form-group address-form-col text-center">
                                            <button type="submit" className="btn fullwidthbtn btn-green ">{btnName}</button>
                                        </div>
                                    </div>
                                </div>





                            </form>


                        </div>


                        <div className={loader}>

                            <Loader className="loaderIcon"
                                type="Bars"
                                color="#3bb78f"
                                height={100}
                                width={100}


                            />

                        </div>

                    </div>



                    <div className={`block-wrap content-block existing-customer-block ${payView}`} >
                        <div className="col-xs-12">
                            <div className="form-group address-form-col text-center">

                                <a href={payUrl} className="btn btn-green fullwidthbtn"><i className="bi bi-check-circle bi-inline"></i>Перейти для оплаты</a>
                            </div>
                        </div>
                    </div>


                </div>
                <div className="block-wrap show-only-mobile">
                    <div className="toggle-component">
                        <div className="wrapper">
                            <Link to="/support" className="btn-white" > <i className="bi bi-question-octagon"></i>Заказ рәсмиләштереп булмый</Link>
                        </div>
                    </div>
                </div>



                <div className="sidebar right">
                    <div className="mini-basket">

                        <div className="heading">
                            <h2>Заказ</h2>
                            <i className="icon-basket"></i>
                            <span className="item-count">
                                {itemCount}</span>
                            <span className="item-total">{total}</span>
                        </div>

                        <div className="wrapper">


                            {
                                cartItems.map((product, idx) =>

                                    <dl key={idx} className="item-row">
                                        <dt>
                                            {product.nameRus}
                                            <br />
                                            <span className="bk-qty">
                                                {product.quantity} шт.</span>
                                        </dt>
                                        <dd className="item-price text-right"></dd>
                                    </dl>


                                )
                            }



                            <dl>
                                <dt><strong>Китерү</strong></dt>
                                <dd className="text-right"><strong>
                                    {shipPrc}</strong></dd>
                            </dl>


                            <dl>
                                <dt className="total"><strong>
                                    Барысы</strong></dt>
                                <dd className="text-right total-price">{totalPrc}</dd>

                            </dl>

                            <Link to="/support" className="btn-white" ><i className="bi bi-question-octagon"></i> Заказ рәсмиләштереп булмый</Link>
                        </div>
                    </div>
                </div>




            </div>



        </div>
    );
}

export default Checkout;
