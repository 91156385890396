import React, { Component, useContext, useState, useEffect } from 'react';


import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import authService from '../../components/api-authorization/AuthorizeService';
import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';



const MyLibrary = () => {

    const [library, setLibrary] = useState(null)
    const [loadStatus, setLoadStatus] = useState("not")


    useEffect(() => {

        if (loadStatus == "not") {
            getMyLibrary()
           
            setLoadStatus("yes")
        }

    },[]);

    async function getMyLibrary() {
        const token = await authService.getAccessToken();
        const url = 'https://tatkniga.ru/api/GetUserLibrary';
        const headersReq = new Headers();
        headersReq.append('Content-Type', 'application/json');
        headersReq.append('Authorization', `Bearer ${token}`);

        const response = await fetch(url, {
            headers: headersReq
        });
        const data = await response.json()
        console.log(data)
        setLibrary(data)


    };

    let itemsToRender;

    
    if (library) {
        itemsToRender = library.data.map(item => {
            return(

            <div key={item.id}>
                <div className="col-sm-2 p-2">
                    <img
                        alt={item.nameRus}
                        style={{ margin: "0 auto", maxHeight: "50px" }}
                        src={item.coverImg} className="img-fluid d-block" />
                </div>
                <div className="col-sm-4 p-2">
                        <h5 className="mb-1">Татарча:  {item.nameTat}</h5>
                        <h5 className="mb-1">Русча: {item.nameRus}</h5>


                </div>

                <div className="col-sm-4 p-2 text-right">

                        <a className="btn  btn-sm mr-2 mb-1" href={'https://old.tatkniga.ru/' + item.oldUrl}> Укырга </a> 

                    </div >


                </div>

                
            )
        });
    } else {
        itemsToRender = "Загрузка...";
    }



    return (
        <div className="container">
            <div className="library">
              <p>  </p>       

            </div>

            <div>
                <div className="card card-body border-0">

           {itemsToRender}
                

                </div>
            </div>



        </div>



    )
}

export default MyLibrary;