import React from 'react';
import SearchBar from '../../components/Sidebar/SearchBar';
import SidebarCategory from '../../components/Sidebar/SidebarCategory';
import CategoryGrid from '../../components/Category/CategoryGrid';
import BookItems from '../../components/BooksGrid/BookItems';
import PropTypes from 'prop-types';
import BookSidebar from '../../components/Sidebar/BookSidebar';
import SideBarList from '../../components/Sidebar/SideBarList';

import TopAuthors from '../../components/Sidebar/TopAuthors';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import ContentLoader from 'react-content-loader';
import BookItemLoader from '../../components/BooksGrid/BookItemLoader';
class Search extends React.Component {

    constructor(props) {
        super(props);
        this.state = { Books: [], loading: true };

    }
    componentDidMount() {
        this.searchData();
        
    }
  
    componentDidUpdate(prevProps) {
    
        if (this.props !== prevProps) {
            this.searchData();;
        }
    }


    static renderSearch(Books) {
        return (
       <>
                


                <div className="main-content  category-page">
                   
                    <div className="block-wrap">
                       


                     
                        
                        <div className="content-block">
                            <div className="block-wrap  paddin-zero">
                                <div className="block">

                                    <div className="block-header">
                                        <h2 className="titleHead">Эзләү:</h2>
                                    </div>

                                    <div className="tab-wrap module type-book grid">
                                        <div className="tab search">

                                            {Books.results.length ?
                                               ( <>
                                                    {Books.results.map((results, idx) =>

                                                        <BookItems key={idx} id={results.id} owl="none" results={results} />


                                                    )}
                                                    </>
                                                ) : (
                                                    <div className="no-book">
                                                        <p>Гафу итегез. Сезнең соравыгыз буенча китаплар юк. </p>
                                                        <img src="/img/no-book.png"/>
                                                    </div>
                                                )
                                            }
                                                         
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        


                    </div>

                </div>
  </>

            
            )
    };



    render() {
        let contents = this.state.loading
            ?<>


                <div className="main-content  category-page">


                    <h1 className="inline-title"> </h1>

                    <div className="content-block">
                        <div className="block-wrap">
                            <div className="block">
                                <div className="block-header">
                                    <h2 className="titleHead">Эзләү:</h2>
                                </div>

                                <div className="tab-wrap module type-book grid">
                                    <div className="tab search">
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
         </>
            : Search.renderSearch(this.state.Books);

        return (
            <div>
              
                <p></p>
                {contents}
            </div>
        );
    }

    

    async searchData() {
        const id = this.props.match.params.id;
      
        let q = new URLSearchParams(this.props.location.search).get("q");
        const response = await fetch('https://tatkniga.ru/api/search?q=' + q);
        
        const data = await response.json();
  

        this.setState({ Books: data, loading: false });


    }
}

export default Search;




 





 


