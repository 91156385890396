import React, { useContext, useEffect } from 'react';
import { browserHistory, Router, Route, Switch, Link } from 'react-router';
import { createBrowserHistory } from 'history';
import Layout from './containers/Layout/Layout';
import { LayoutBase } from './containers/Layout/LayoutBase';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import Genres from './containers/Genres/Genres';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import Home from './containers/Home/Home';
import Authors from './containers/Authors/Authors';
import Author from './containers/Author/Author';
import Contact from './containers/Contact/Contact';

import StoreGenres from './containers/Store/StoreGenres';
import Store from './containers/Store/Store';

import BookReader from './containers/BookReader/BookReader';
import BookReaderPdf from './containers/BookReader/BookReaderPdf';
import Support from './containers/Support/Support';
import { About } from './containers/About/About';
import Book from './containers/Book/Book';
import BookAr from './containers/BookAr/BookAr';
import PrivacyAr from './containers/BookAr/PrivacyAr';
import PublicOffers from './containers/PublicOffers/PublicOffers';
import BookContent from './containers/Book/BookContent';
import NotFoundPage from './containers/NotFoudPage/NotFoundPage';
import Library from './containers/Library/Library';
import BookLib from './containers/Library/BookLib';
import Search from './containers/Search/Search';
import NewsData from './containers/News/NewsData';
import NewsList from './containers/News/NewsList';
import AudioList from './containers/Audio/AudioList';
import AudioBook from './containers/Audio/AudioBook';
import Account from './containers/Account/Account';
import MyOrders from './containers/Account/MyOrders';
import OrderDetails from './containers/Account/OrderDetails';
import MyLibrary from './containers/Account/MyLibrary';
import Cart from './containers/Cart/Cart';
import Checkout from './containers/Checkout/Checkout';
import YkassaCheck from './containers/Ykassa/YkassaCheck';
import OurStores from './containers/OurStores/OurStores';
import OrderSuccess from './containers/OrderStatus/OrderSuccess';
import Track from './containers/Track/Track';
import './custom.css';
import OrderPayPdf from './containers/OrderStatus/OrderPayPdf';
import { CartContext } from './contexts/CartContext';

import Sales from './containers/Sales/Sales';



export default function App(props) {

    const { addProduct, cartItems, increase, resetCart } = useContext(CartContext);


    useEffect(() => {

        window.addEventListener('storage', storageChanged);
        return () => {
            window.addEventListener('storage', storageChanged);
        };
    }, []);





    function storageChanged({ key, oldValue, newValue }) {
        if (key === "cart") {



            if (JSON.stringify(oldValue) != JSON.stringify(newValue)) {

                resetCart(JSON.parse(newValue));
            }


        }
    }

    return (
        <div>

            <Layout>
                <Switch>
                    <Route exact path='/' render={(props) => (<LayoutBase> <Home {...props} /> </LayoutBase>)} />
                    <Route path='/counter' component={Counter} />


                    <Route path='/books/:id' component={Book} />
                    <Route path='/book-content/:id' component={BookContent} />

                    <Route path='/search/' render={(props) => (<LayoutBase> <Search {...props} /> </LayoutBase>)} />
                    <Route path='/store/genres/:id' render={(props) => (<LayoutBase> <Genres {...props} /> </LayoutBase>)} />

                    <Route path='/authors/'><LayoutBase><Authors /></LayoutBase></Route>


                    <Route path='/support/'><LayoutBase><Support /></LayoutBase></Route>
                    <Route path='/store/'><LayoutBase><Store /></LayoutBase></Route>

                    <Route path='/sales/' render={(props) => (<LayoutBase> <Sales {...props} /> </LayoutBase>)} />

                    <Route path='/track/:id'><LayoutBase><Track /></LayoutBase></Route>
                    <Route path='/library/' render={(props) => (<LayoutBase> <Library {...props} /> </LayoutBase>)} />

                    <Route path='/audio-books/' render={(props) => (<LayoutBase> <AudioList {...props} /> </LayoutBase>)} />
                    <Route path='/audio-book/'><LayoutBase><AudioBook /></LayoutBase></Route>

                    <Route path='/tatkitap-ar'><LayoutBase><BookAr /></LayoutBase></Route>
                    <Route path='/app-privacy-policy'><LayoutBase><PrivacyAr /></LayoutBase></Route>
                    <Route path='/public-offers' ><LayoutBase><PublicOffers /></LayoutBase></Route>


                    <Route path='/contact-us'><LayoutBase><Contact /></LayoutBase></Route>



                    <Route path='/order-success' component={OrderSuccess} />
                    <Route path='/order-pay/:id' component={OrderPayPdf} />
                    <Route path='/author/:id' render={(props) => (<LayoutBase> <Author {...props} /> </LayoutBase>)} />

                    <Route path="/newslist/" render={(props) => (<LayoutBase> <NewsList {...props} /> </LayoutBase>)} />

                    <Route path="/news/:id" render={(props) => (<LayoutBase> <NewsData {...props} /> </LayoutBase>)} />


                    <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />


                    <Route path="/booklib" component={BookLib} />
                    <Route path="/readpdf" component={BookReaderPdf} />
                    <Route path="/readepub" component={BookReader} />
                    <AuthorizeRoute path="/checkout" component={Checkout} />


                    <Route path="/about"  ><LayoutBase><About /></LayoutBase></Route>
                    <Route path="/cart" component={Cart} />


                    <Route path="/ykassacheck/:id" component={YkassaCheck} />
                    <AuthorizeRoute path="/account" component={Account} />

                    <AuthorizeRoute path="/myorders" component={MyOrders} />
                    <AuthorizeRoute path="/mylibrary" component={MyLibrary} />

                    <AuthorizeRoute path="/orderdetails/:id" component={OrderDetails} />


                    <Route path='/our-stores' component={OurStores} />
                    <Route path="/404" component={NotFoundPage} />
                    <Route component={NotFoundPage} />
                </Switch>
            </Layout>

        </div>



    );

}
