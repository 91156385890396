import React, { useContext, useState } from "react";
import './navmenu.css';
import { Link } from "react-router-dom";
import { CartContext } from '../../contexts/CartContext';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
import { slide as Menu } from 'react-burger-menu';
import { BurgerMenuContext } from '../../contexts/BurgerMenuContext';
import Hamburger from 'hamburger-react'

const HeaderMenu = (props) =>  {







    const { itemCount } = useContext(CartContext);
    const { total } = useContext(CartContext);
    const ctx = useContext(BurgerMenuContext)
        return (
            <>

              

            


            <Navbar bg="light" variant="light" className="mainnav" expand="lg" >
                    <div className="secondary-header">

                        <div className="burger-menu-btn">
                            <a onClick={ctx.toggleMenu}>
                                <Hamburger toggled={ctx.isMenuOpen} onClick={ctx.toggleMenu} /> 
                            </a>
                        </div>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mx-auto">
                                <Nav.Link as={Link} to="/">Баш
бит</Nav.Link>

                                <Nav.Link as={Link} to="/store">Кибет</Nav.Link>
                                <Nav.Link as={Link} to="/library">Китапханә</Nav.Link>
                                <Nav.Link as={Link} to="/sales">Акцияләр</Nav.Link>
                                <Nav.Link as={Link} to="/audio-books/" >Аудиокитаплар</Nav.Link>
                                <Nav.Link as={Link} to="/authors">Авторлар</Nav.Link>
                                <Nav.Link as={Link} to="/newslist/">Яңалыклар</Nav.Link>

                    </Nav>

                        </Navbar.Collapse>
                      
                        <div className="right-section">

                         
                        <div className="basket-wrap">
                            <span className="total">{total} Руб</span>
                           <Link to='/cart'  className="basket-btn">
                                <span className="item-count">
                                    </span>


                                <i> ({itemCount})</i>

                                
                                <i className="icon-basket"></i>


                        </Link>
                    </div>
                    </div>
                    </div>
            </Navbar>


                </>

            

        );    
}

export default HeaderMenu;