import React, { createContext, useReducer } from 'react';
import { CartReducer, sumItems } from './CartReducer';
import Cookies from 'universal-cookie';


const cookies = new Cookies();
let p = Date.now();

if (cookies.get('cartuid') === undefined) {
    cookies.set('cartuid', p, { path: '/', maxAge: 36000, domain: "tatkniga.ru" });
}

const cuid = cookies.get('cartuid');
const url = `https://tatkniga.ru/api/cartbook?sessionId=` + cuid;


var xhr = new XMLHttpRequest();
xhr.open('GET', url, false);

xhr.send();

if (xhr.status != 200) {
    // обработать ошибку

} else {
    // вывести результат
    let datas = JSON.parse(xhr.responseText);

    if (datas != "null" && datas != null) {
        localStorage.setItem('cart', xhr.responseText.length > 0 ? datas : []);
    }
    else
        localStorage.setItem('cart', []);

}




const storage = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [];


export const CartContext = createContext()



const initialState = { cartItems: storage, ...sumItems(storage), checkout: false };






const CartContextProvider = ({ children }) => {

    const [state, dispatch] = useReducer(CartReducer, initialState);



    const increase = payload => {
        dispatch({ type: 'INCREASE', payload })
    }

    const decrease = payload => {
        dispatch({ type: 'DECREASE', payload })
    }

    const addProduct = payload => {
        dispatch({ type: 'ADD_ITEM', payload })
    }

    const removeProduct = payload => {
        dispatch({ type: 'REMOVE_ITEM', payload })
    }

    const clearCart = () => {
        dispatch({ type: 'CLEAR' })
    }

    const resetCart = payload => {
        dispatch({ type: 'RESET', payload })
    }

    const handleCheckout = () => {
        console.log('CHECKOUT', state);
        dispatch({ type: 'CHECKOUT' })
    }

    const contextValues = {
        removeProduct,
        addProduct,
        resetCart,
        increase,
        decrease,
        clearCart,
        handleCheckout,
        ...state
    }

    return (
        <CartContext.Provider value={contextValues} >
            {children}
        </CartContext.Provider>
    );
}

export default CartContextProvider;