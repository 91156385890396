import React from 'react';
import SearchBar from '../../components/Sidebar/SearchBar';
import SidebarCategory from '../../components/Sidebar/SidebarCategory';
import CategoryGrid from '../../components/Category/CategoryGrid';
import BookItems from '../../components/BooksGrid/BookItems';

import SideBarList from '../../components/Sidebar/SideBarList';

import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import BookItemLoader from '../../components/BooksGrid/BookItemLoader';


class StoreGenres extends React.Component {

    constructor(props) {
        super(props);
        this.state = { GenreBooks: [], Meta: [], loading: true, nexturl: false, pervurl: false};

    }

  
    componentDidMount() {
        window.scrollTo(0, 0);
        this.genreBooksData();
        
    }
    componentDidUpdate(prevProps) {

        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
            this.genreBooksData();

            window.scrollTo(0, 0);
        }
    }



    static renderGenreBooks(Meta, GenreBooks, nexturl, pervurl) {
        return (
      <>



                <div className="main-content  category-page">

                  
                        <h1 className="inline-title"> </h1>




                        <div className="content-block">
                            <div className="block-wrap">
                                <div className="block">
                                    <div className="tab-wrap module type-book grid">
                                        <div className="tab search">
                                        {GenreBooks.results.map((results, idx) =>

                                            <BookItems key={idx} id={results.id} owl="none" results={results} />



                                        )
                                        }


                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                    <div className="foot-block">
                        <div className="text-center">


                                {pervurl ? (
                                   
                                <Link to={GenreBooks.previousUrl}> <div className="btnP btn">Элек </div></Link>
                                    
                                ) : (
                                        <div>

                                        </div>
                                    )
                                }



                             {nexturl ? (
                                   
                                <Link to={GenreBooks.nextUrl}> <div className="btnN btn">Аннары </div></Link>
                                                
                                  ) : (
                                <div>
                                         
                                                </div>
                                  )
                                }

                             



                            </div>
                        </div>



                    

                </div>
       </>


        )
    };





    render() {
        let contents = this.state.loading
            ? <div className="content-wrap">
                <div className="sidebar">
                    <SideBarList/>

                </div>



                <div className="main-content  category-page">


                    <h1 className="inline-title"> </h1>




                    <div className="content-block">
                        <div className="block-wrap">
                            <div className="block">
                                <div className="tab-wrap module type-book grid">
                                    <div className="tab search">
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />


                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="foot-block">
                        <div className="text-center">

                        </div>
                    </div>





                </div>
            </div>

            : StoreGenres.renderGenreBooks(this.state.Meta, this.state.GenreBooks, this.state.nexturl, this.state.pervurl);

        return (
            <div>

                <p></p>
                {contents}
            </div>
        );
    }


    async genreBooksData() {

        const id = this.props.match.params.id;
        console.log(this.props.match.params.id);

        

        const search = this.props.location.search; // 
        const params = new URLSearchParams(search);
        let pg = params.get('PageNumber'); // 
        
        console.log(pg);
        if (pg == null) {
            pg = 1;
        }
        
    
       

        const response = await fetch('https://tatkniga.ru/api/GenreBooks?id=' + id + '&PageNumber=' + pg + '&BookType=0');

        const meta = JSON.parse(response.headers.get('x-pagination'));


  



        const data = await response.json();

        


        this.setState({ GenreBooks: data, Meta: meta, loading: false, nexturl: meta.HasNext, pervurl: meta.HasPrevious});
        

    }

}

export default StoreGenres;













