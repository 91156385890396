import React from 'react';
import HeaderWrap from './HeaderWrap';
import NavTop from './NavTop';
import HeaderMenu from './HeaderMenu';
 
const headLayout = (props) =>{
        return(
                <>
                <NavTop/>
                <HeaderWrap/>
                <HeaderMenu />

   
                </>
        );
}

export default headLayout;