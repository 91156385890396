import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import 'reactjs-popup/dist/index.css'; import Popup from 'reactjs-popup';

import { CartContext } from '../../contexts/CartContext';
import {
    FacebookShareCount,
    PinterestShareCount,
    VKShareCount,
    OKShareCount,
    RedditShareCount,
    TumblrShareCount,
    HatenaShareCount,
    FacebookShareButton,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    LinkedinShareButton,
    TwitterShareButton,
    PinterestShareButton,
    VKShareButton,
    OKShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    RedditShareButton,
    EmailShareButton,
    TumblrShareButton,
    LivejournalShareButton,
    MailruShareButton,
    ViberShareButton,
    WorkplaceShareButton,
    LineShareButton,
    WeiboShareButton,
    PocketShareButton,
    InstapaperShareButton,
    HatenaShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    PinterestIcon,
    VKIcon,
    OKIcon,
    TelegramIcon,
    WhatsappIcon,
    RedditIcon,
    TumblrIcon,
    MailruIcon,
    EmailIcon,
    LivejournalIcon,
    ViberIcon,
    WorkplaceIcon,
    LineIcon,
    PocketIcon,
    InstapaperIcon,
    WeiboIcon,
    HatenaIcon,
} from "react-share";
import Zoom from 'react-medium-image-zoom'
import OwlCarousel from 'react-owl-carousel-loop';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import 'react-medium-image-zoom/dist/styles.css'
function BookItemBlock(props) {

    const { addProduct, cartItems, increase } = useContext(CartContext);






    const isInCart = product => {
        return !!cartItems.find(item => item.id === product.id);
    }
    let shareUrl = window.location.href;


    const readUrl = "/book-content/" + props.books.id;

    return (

        <div className="item-block">

            <div className="item-img">
                <i className="icon-mobile-zoom"></i>
                <div className="item-img-content">
                    {props.books.sales === 1 &&
                        <div className="bookPartner">Акция</div>
                    }
                    <Zoom>
                        <img
                            src={`https://tatkniga.ru/${props.books.coverImg}`}
                            alt={props.books.coverImg}
                            className="book-img" />
                    </Zoom>



                    {props.books.isPartner == true &&
                        <div className="bookPartner">Партнёр</div>
                    }




                </div>
                {props.books.bookImageView != null &&
                    <div className="addimage">
                        <OwlCarousel
                            className="owl-theme"
                            loop
                            margin={10}
                            nav
                            dots={false}
                            autoWidth={true}
                            autoplay={true}
                            autoHeight={true}  /* This is the option enabled */
                        >
                            {props.books.bookImageView.map((results, idx) =>

                                <div key={idx} className={`book-img-item owlwidth`}>
                                    <Zoom>
                                        <img src={`/upload/images/books/addimages/${results.url}`} />
                                    </Zoom>


                                </div>




                            )}
                        </OwlCarousel>
                    </div>
                }


                <div className="copy-content visible-md" data-target=".social-wrap">
                    <ul className="social-wrap"></ul>

                </div>



            </div>


            <div className="item-info">
                <h1> {props.books.nameTat} </h1>
                <div className="soc">


                    <div className="Demo__some-network">
                        <VKShareButton
                            url={shareUrl}
                            image={`${String(window.location)}/${props.books.coverImg}`}
                            className="Demo__some-network__share-button"
                        >
                            <VKIcon size={32} round />
                        </VKShareButton>

                        <div>
                            <VKShareCount url={shareUrl} className="Demo__some-network__share-count" />
                        </div>
                    </div>

                    <div className="Demo__some-network">
                        <OKShareButton
                            url={shareUrl}
                            image={`${String(window.location)}/${props.books.coverImg}`}
                            className="Demo__some-network__share-button"
                        >
                            <OKIcon size={32} round />
                        </OKShareButton>

                        <div>
                            <OKShareCount url={shareUrl} className="Demo__some-network__share-count" />
                        </div>
                    </div>


                    <div className="Demo__some-network">
                        <WhatsappShareButton
                            url={shareUrl}
                            title={props.books.nameTat}
                            separator=":: "
                            className="Demo__some-network__share-button"
                        >
                            <WhatsappIcon size={32} round />
                        </WhatsappShareButton>

                        <div className="Demo__some-network__share-count">&nbsp;</div>
                    </div>

                    <div className="Demo__some-network">
                        <TwitterShareButton
                            url={shareUrl}
                            title={props.books.nameTat}
                            className="Demo__some-network__share-button"
                        >
                            <TwitterIcon size={32} round />
                        </TwitterShareButton>
                    </div>

                    <div className="Demo__some-network">
                        <FacebookShareButton
                            url={shareUrl}
                            quote={props.books.nameTat}
                            className="Demo__some-network__share-button"
                        >
                            <FacebookIcon size={32} round />
                        </FacebookShareButton>

                    </div>

                    <div className="Demo__some-network">
                        <TelegramShareButton
                            url={shareUrl}
                            title={props.books.nameTat}
                            className="Demo__some-network__share-button"
                        >
                            <TelegramIcon size={32} round />
                        </TelegramShareButton>
                    </div>



                </div>


                <ul className="meta-info hidden-md">
                    <li>Русча</li>
                    <li>
                        <span>
                            {props.books.nameRus}
                        </span>
                    </li>
                </ul>


                <div className="author-info hidden-md">
                    (Автор)&nbsp;

                    <span >
                        {props.authors.authorsNameViewModel.map((authorsNameViewModel, idx) =>


                            <Link key={idx} to={authorsNameViewModel.url}>

                                <span>
                                    {authorsNameViewModel.nameRus}
                                </span>
                            </Link>
                        )}
                    </span>
                </div>
                <ul className="social-wrap hidden-xs hidden-sm hidden-md"></ul>
                <div
                    className="item-excerpt trunc" dangerouslySetInnerHTML={{ __html: props.books.descriptionRus }}>

                </div>
            </div>
            <div className="item-tools">
                <div className="checkout-tools">
                    <div className="copy-content visible-md">

                    </div>

                    <div className="price-info-wrap">
                        <div className="item-info-wrap">
                            <div className="copy-content visible-md" data-target=".meta-info">
                                <ul className="meta-info">
                                    <li>Книга</li>
                                    <li>
                                        <span >

                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="copy-content visible-md" data-target=".author-info">
                                <div className="author-info">
                                    (автор)&nbsp;

                                    <span>
                                        {props.authors.authorsNameViewModel.map((authorsNameViewModel, idx) =>
                                            <span key={idx} >
                                                <a href={authorsNameViewModel.url} >

                                                    <span>
                                                        {authorsNameViewModel.nameRus}
                                                    </span>
                                                </a>
                                            </span>
                                        )}
                                    </span>
                                </div>

                            </div>
                            <div className="price item-price-wrap hidden-xs hidden-sm">


                                {props.books.price > 0 &&
                                    <span className="sale-price">

                                        {props.books.price}

                                        ₽</span>


                                }


                                {props.books.price === 0 &&
                                    <span className="sale-price">

                                    Бушлай

                                        ₽</span>
                                }


                                {props.books.sales === 1 &&

                                    <span className="list-price">{props.books.oldPrice} ₽</span>
                                }


                                <span className="price-save red-text">
                                </span>
                            </div>


                            {props.books.price > 0 &&
                                <div>
                                    <div
                                        className="copy-content visible-md visible-xs visible-sm"
                                        data-target=".formats-wrap"></div>
                                    <div className="free-delivery-text">
                                        <i className="icon-rocket"></i>
                                        <p>Доставка в любую точку</p>
                                    </div>
                                    <div className="availability-text">
                                        <i className="icon-check"></i>
                                        <div>
                                            {props.books.totalQuantity > 0 &&
                                            <span>Китап бар</span>
                                            }

                                            {props.books.totalQuantity == 0 &&
                                            <span>Китап юк</span>
                                            }

                                            <br />
                                        <div target="_blank" href="#">Кайчан килер минем заказ?</div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {props.books.oldUrl ? (
                                <div className="btn-wrap hidden-xs hidden-sm">

                                    <a className="btn btn-primary btn-sm" href={'https://old.tatkniga.ru/' + props.books.oldUrl}><i className="bi bi-file-text  bi-inline"></i>Иске форматта укырга </a>
                                </div>
                            ) : (
                                <div>

                                </div>
                            )
                            }




                            {props.books.pdf ? (
                                <div className="btn-wrap hidden-xs hidden-sm">

                                    <a className="btn btn-primary btn-sm" href={'https://tatkniga.ru/StaticFiles/pdf/content/' + props.books.pdf} target="_blank" ><i className="bi bi-file-earmark-pdf  bi-inline"></i> PDF укырга </a>
                                </div>
                            ) : (
                                <div>

                                </div>
                            )
                            }


                        </div>
                    </div>



                    <div className="btn-wrap">

                        <div className="copy-content visible-xs visible-sm">
                            <div className="price item-price-wrap">

                                <span className="sale-price">{props.books.price} ₽</span>

                                {props.books.sales === 1 &&
                                    <span className="list-price">{props.books.oldPrice} ₽</span>
                                }
                                <span className="price-save red-text"></span>


                                {props.books.oldUrl ? (
                                    <div className="btn-wrap">
                                        <a className="btn btn-primary btn-sm" href={'https://old.tatkniga.ru/' + props.books.oldUrl}><i className="bi bi-file-text  bi-inline"></i> Иске форматта укырга </a>
                                    </div>
                                ) : (
                                    <div>

                                    </div>
                                )

                                }



                                {props.books.pdf ? (
                                    <div className="btn-wrap">

                                        <a className="btn btn-primary btn-sm" href={'https://tatkniga.ru/StaticFiles/pdf/content/' + props.books.pdf} target="_blank" ><i className="bi bi-file-earmark-pdf  bi-inline"></i>  PDF укырга</a>
                                    </div>
                                ) : (
                                    <div>

                                    </div>
                                )
                                }




                            </div>
                        </div>

                        {
                            isInCart(props.books) && props.books.price > 0 && props.books.totalQuantity > 0 &&
                            <Link to="/cart"

                                className="btn btn-green btn-sm"><i className="bi bi-cart-check bi-inline"></i>өстәлгән.<span> Кәрзингә күчү</span></Link>
                        }


                        {
                            !isInCart(props.books) && props.books.price > 0 && props.books.totalQuantity > 0 &&
                            <button
                                onClick={() => addProduct(props.books)}
                                className="btn btn-buy"> <i className="bi bi-bag-plus bi-inline"></i>Кәрзингә өстәргә</button>
                        }
                        {props.books.contentEpub &&


                            <Link className="btn btn-primary btn-sm" to={readUrl}> <i className="bi bi-file-text  bi-inline"></i>Эчтәлек</Link>
                        }

                        {props.books.archiveFile &&

                            <a href={props.books.archiveFile} download className="btn btn-green btn-sm"><i className="bi bi-cloud-arrow-down bi-inline"></i>Күчереп алу</a>
                        }

                        <div>

                            <div className="col" >
                                <div className="product-item">
                                    <div className="pi-pic">
                                        <div className="pi-links">

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>



                        <div className="copy-content visible-xs visible-sm" >

                        </div>
                    </div>
                </div>
            </div>
        </div>

    )


}

export default BookItemBlock;