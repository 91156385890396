import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap'
import OwlCarousel from 'react-owl-carousel-loop';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';



const StoreCatList = () => {

    return (
        <div className="catgrid">
    
            <div className="ps-block--category">
                <div class="blur-cat"></div>
                    <Nav.Link as={Link} to="/store/genres/2" className="ps-block__overlay" >
                       
                    <p>Балалар әдәбияты</p>
                </Nav.Link>
             </div>

       


            <div className="ps-block--category">
                <div class="blur-cat"></div>
                    <Nav.Link as={Link} to="/store/genres/19" className="ps-block__overlay" >
                      
                    <p>Шигърият</p>
                    </Nav.Link>
                </div>

            <div className="ps-block--category">
                <div class="blur-cat"></div>
                    <Nav.Link as={Link} to="/store/genres/20" className="ps-block__overlay" >
                       
                    <p>Проза</p>
                    </Nav.Link>
                </div>

            <div className="ps-block--category">
                <div class="blur-cat"></div>
                    <Nav.Link as={Link} to="/store/genres/21" className="ps-block__overlay" >
                       
                    <p>Публицистик</p>
                    </Nav.Link>
                </div>
            <div className="ps-block--category">
                <div class="blur-cat"></div>
                    <Nav.Link as={Link} to="/store/genres/10" className="ps-block__overlay" >
                        
                    <p>Белешмә әдәбият</p>
                    </Nav.Link>
                </div>

            <div className="ps-block--category">
                <div class="blur-cat"></div>
                    <Nav.Link as={Link} to="/store/genres/15" className="ps-block__overlay" >
                       
                    <p>Мәгариф</p>
                    </Nav.Link>
            </div>


            <div className="ps-block--category">
                <div class="blur-cat"></div>
                <Nav.Link as={Link} to="/store/genres/12" className="ps-block__overlay" >
                  
                    <p>Рус теллендә мәктәпләр өчен (рус)</p>
                </Nav.Link>
            </div>

            <div className="ps-block--category">
                <div class="blur-cat"></div>
                <Nav.Link as={Link} to="/store/genres/13" className="ps-block__overlay" >
                  
                    <p>Рус теллендә мәктәпләр өчен (тат)</p>
                </Nav.Link>
            </div>

            <div className="ps-block--category">
                <div class="blur-cat"></div>
                <Nav.Link as={Link} to="/store/genres/14" className="ps-block__overlay" >
                    
                    <p>Татар теллендә мәктәпләр өчен</p>
                </Nav.Link>
            </div>

            <div className="ps-block--category">
                <div class="blur-cat"></div>
                <Nav.Link as={Link} to="/store/genres/7" className="ps-block__overlay" >
                  
                    <p>Туган якны өйрәнү әдәбияты</p>
                </Nav.Link>
            </div>

            <div className="ps-block--category">
                <div class="blur-cat"></div>
                <Nav.Link as={Link} to="/store/genres/8" className="ps-block__overlay" >
                  
                    <p>Музыка</p>
                </Nav.Link>
            </div>

            <div className="ps-block--category">
                <div class="blur-cat"></div>
                <Nav.Link as={Link} to="/store/genres/9" className="ps-block__overlay" >
                   
                    <p>Фәнни-популяр әдәбият</p>
                </Nav.Link>
            </div>
            <div className="ps-block--category">
                <div class="blur-cat"></div>
                <Nav.Link as={Link} to="/store/genres/6" className="ps-block__overlay" >
                   
                    <p>Фән һәм сәнгать</p>
                </Nav.Link>
            </div>



       

            <div className="ps-block--category">
                <div class="blur-cat"></div>
                <Nav.Link as={Link} to="/store/genres/3" className="ps-block__overlay" >
                   
                    <p>Уеннар</p>
                </Nav.Link>
            </div>

            <div className="ps-block--category">
                <div class="blur-cat"></div>
                <Nav.Link as={Link} to="/store/genres/5" className="ps-block__overlay" >
                    <p>Татар телен өйрән</p>
                </Nav.Link>
            </div>

            <div className="ps-block--category">
                <div class="blur-cat"></div>
                    <Nav.Link as={Link} to="/store/genres/16" className="ps-block__overlay" >
                        
                    <p>Федераль дәреслекләр (тәрҗемәләр)</p>
                    </Nav.Link>
                </div>

            <div className="ps-block--category">
                <div class="blur-cat"></div>
                    <Nav.Link as={Link} to="/store/genres/17" className="ps-block__overlay" >
                       
                    <p>СМИ АО "Татмедиа"</p>
                    </Nav.Link>
                </div>

                

                
        </div>

        );
}

export default StoreCatList;