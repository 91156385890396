import React from 'react';
import SearchBar from '../../components/Sidebar/SearchBar';
import SidebarCategory from '../../components/Sidebar/SidebarCategory';
import CategoryGrid from '../../components/Category/CategoryGrid';
import BookItems from '../../components/BooksGrid/BookItems';
import PropTypes from 'prop-types';
import BookSidebar from '../../components/Sidebar/BookSidebar';
import TopAuthors from '../../components/Sidebar/TopAuthors';
import SideBarList from '../../components/Sidebar/SideBarList';

import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import ContentLoader from 'react-content-loader';
import BookItemLoader from '../../components/BooksGrid/BookItemLoader';


class Authors extends React.Component {

    constructor(props) {
        super(props);
        this.state = { AuthorsList: [], loading: true };

    }
    componentDidMount() {
        this.authorsData();
        
    }
    componentDidUpdate(prevProps) {

        if (this.props !== prevProps) {
            this.authorsData();
            this.backToTop();
            window.scrollTo(0, 0);
        }
    }

    backToTop() {
        if (window.pageYOffset > 0) {
            //let Y = window.pageYOffset - 10;
            window.scrollBy(0, 0);
            //setTimeout(this.backToTop, 0);
        }
    }
 


    static renderAuthors(AuthorsList) {
        return (
         <>

                <Helmet>
                    <title>Татарское книжное издательство - список авторов издательства</title>
                    <meta name="description" content="список авторов издательства татарскокого книжного издательства»" />
                </Helmet>

          



                <div className="main-content  category-page">

                    <div className="block-wrap">
              
                        



                        <div className="content-block">
                            <div className="block-wrap">
                                <div className="block-header">
                                    <h2 className="titleHead">Авторлар</h2>
                                </div>
                                <div className="block">
                                    <div className="tab-wrap module type-book grid">
                                        <div className="tab search">
                                            {AuthorsList.results.map(results =>

                                                <div key={results.authorsId} className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
                                                    <div className="kode-author kode-author-2">
                                                        <Link to={results.url}><img src="https://tatkniga.ru/upload/images/authors/author-icon1.png" alt="image description" /></Link>
                                                            <div className="kode-caption">
                                                                <h4><Link to={results.url}> {results.nameTat} </Link></h4>
                                                      
                                                            </div>
								</div>
                                                    </div>

                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                    </div>

            </div>
       </>
      

        )
    };



    render() {
        let contents = this.state.loading
            ?<>


                <div className="main-content  category-page">


                    <h1 className="inline-title"> </h1>

                    <div className="content-block">
                        <div className="block-wrap">
                            <div className="block">
                                <div className="block-header">
                                    <h2 className="titleHead">Авторлар</h2>
                                </div>

                                <div className="tab-wrap module type-book grid">
                                    <div className="tab search">
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                        <BookItemLoader />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           </>
            : Authors.renderAuthors(this.state.AuthorsList);

        return (
            <div>                
                {contents}
            </div>
        );
    }



    async authorsData() {
        
        
        
        const response = await fetch('https://tatkniga.ru/api/authors/');


        const data = await response.json();
        
     

        this.setState({ AuthorsList: data, loading: false });


    }
}

export default Authors;













