import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router";
import axios from 'axios';
import { LoginMenu } from '../../components/api-authorization/LoginMenu';



// s#Using_Special_Characters
function getSuggestionValue(suggestion) {
    return suggestion.nameRus;
  }
  
  function renderSuggestion(suggestion) {
    return (
        <span>{suggestion.nameRus}</span>
    );
  }
  

class Headerwrap extends React.Component {
constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
        value: '',
        
        suggestions: []
    };
}

onChange = (event, { newValue, method }) => {
    console.log(newValue)
    this.setState({
      value: newValue
    });
    };

    handleSubmit = (event) => {
        event.preventDefault()
        const value = event.target[0].value;
        
        this.context.router.history.push(`/search?q=${value}`);
       
           
    }
  
  onSuggestionsFetchRequested = ({ value }) => {
      fetch(`https://tatkniga.ru/api/SearchShort?value=${value}`)
      .then(response => response.json())
      .then(data => this.setState({ suggestions: data.results }))
  }

  onSuggestionsClearRequested = () => {
      this.setState({
          
          suggestions: []
    });
  };

    clearSuggs = () => {
        this.setState({
            value: '',
            suggestions: []
        });
    };

render() {
    const {value, suggestions} = this.state;
    const inputProps = {
        placeholder: "Китап исеме буенча эзләү/ автор / ISBN",
        value,
        onChange: this.onChange
    };
    
    let ulrtat = window.location.href;
        
    let ulrtatnew = ulrtat.replace('https://tat.tatkniga.ru', 'https://tatkniga.ru');
   
    return (
        <div className="header-wrap ">
            <header className="header ">
                

                <div className="primary-wrap">

                    <div className="brand-wrap">
                        <h1>
                            <Link to="/" className="brand-link">
                                <img src="/img/logo_ta.svg" alt="tatkniga.ru"/>
                            </Link>
                        </h1>
                    </div>
                    
                    <div className="show-non-desktop">     <LoginMenu className="mob"></LoginMenu>   </div>
                   

                    <div className="search-wrap">
                        <form id="book-search-form" onSubmit={this.handleSubmit}>
                            <div className="el-wrap header-search-el-wrap">
                                <div className="searchin"> 
                                    <Autosuggest 
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                    getSuggestionValue={getSuggestionValue}
                                    renderSuggestion={renderSuggestion}
                                                                    inputProps={inputProps} />

                                    <div className="header-clean-btn" onClick={this.clearSuggs}>
                                    <span className="text">X</span>
                                    </div>

                                </div>
                                <button aria-label="Search" className="header-search-btn" type="submit">
                                    <span className="text">Эзләү</span>
                                </button>
                            </div>
                            <div className="result-wrap no-result">
                                <div className="suggest-result"></div>
                                <div className="book-result"></div>
                            </div>
                        </form>
                        
                        
                        <div className="bg-screen"></div>
                    </div>

                    <div className="free-delivery-wrap">
                        <div className="free-delivery">
                       
                        </div>
                    </div>

                </div>

              

            </header>
        </div>
    );
}
}

export default Headerwrap;

Headerwrap.contextTypes = {
    router: PropTypes.object.isRequired
}
