import React from 'react';
import Brcrumb from '../../components/Book/Brcrumb';
import BookItemBlock from '../../components/Book/BookItemBlock';
import BookDetails from '../../components/Book/BookDetails';
import PropTypes from 'prop-types';

import ReactJkMusicPlayer from 'react-jinke-music-player'
import 'react-jinke-music-player/assets/index.css'
import LastBook from '../../components/BooksGrid/LastBook';
import ContentLoader from 'react-content-loader';
import { Helmet } from "react-helmet";

import {
    EpubView, // Underlaying epub-canvas (wrapper for epub.js iframe)
    EpubViewStyle, // Styles for EpubView, you can pass it to the instance as a style prop for customize it
    ReactReader, // A simple epub-reader with left/right button and chapter navigation
    ReactReaderStyle // Styles for the epub-reader it you need to customize it
} from "react-reader";

class BookContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = { books: [], loading: true };

    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.bookData();
        
        
    }

    componentDidUpdate(prevProps) {

        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
            this.bookData();

            window.scrollTo(0, 0);
        }
    }





    static renderBook(books) {
        return (

            <div className="bookreaderepub">
                <Helmet>
                    <title>Татарское книжное издательство - {books.bookViewModel.nameTat}    </title>
                    <meta name="description" content="{books.bookViewModel.nameTat} - {books.bookViewModel.nameRus}" />
                </Helmet>
                {books.bookViewModel.contentEpub}
                <ReactReader
                    url={`https://tatkniga.ru/staticfiles/epub/preview/${books.bookViewModel.contentEpub}`}
                        title={books.bookViewModel.nameTat} 

              location={"epubcfi(/6/2[cover]!/6)"}
          locationChanged={epubcifi => console.log(epubcifi)}
          tocChanged={toc => console.log(toc)}
                    />
           
            </div>
        )
    };




    render() {
        let contents = this.state.loading
            ? <div className="content-wrap paddin-zero" >
                <div className="main-content no-borders book-page full-width">

                </div> 
            </div>



            : BookContent.renderBook(this.state.books);

        return (
            <div>

                <p></p>
                {contents}
            </div>
        );
    }

    async bookData() {
        const id = this.props.match.params.id;

        const response = await fetch('https://tatkniga.ru/api/books/' + id);
        const data = await response.json();


        this.setState({ books: data, loading: false });


    }

}
export default BookContent;


