import React from 'react';
import AccountSideBar from '../../components/Sidebar/AccountSideBar';

import { useForm } from "react-hook-form";

export default function Contact() {
    const { register, errors, handleSubmit } = useForm({
        mode: "onBlur"
    });
    const onSubmit = data => {
        alert(JSON.stringify(data));
    };
    return (
        <div className="content-wrap">

            <div className="sidebar">
                <AccountSideBar/>
            </div>
            <div className="main-content ">
                <div className="content">
                    <div className="sendmes">
                        <h1>Безгә языгыз</h1>

                    <div className="form-wrap contact-page">

                        <p className="text">
                                Җавап таба алмасагыз каразы  <a href="/help">ярдәм бүлеген</a> ?<br />

                                   Зинһар, тутырыгыз бу формасын:<br />
                                        Басулар мәҗбүри  <sup className="red-text">*</sup> билгеләнгән.
                              </p>
                        <form onSubmit={handleSubmit(onSubmit)}>

                   

                                <div className="form-group required ">
                                     <label className="control-label"  htmlFor="firstName">ФИО</label>
                                      <div className="form-control-wrap">
                                         <input name="Name" className="form-control" ref={register({ required: true, maxLength: 20 })} />
                                          {errors.Name && <p>Это поле обязательно для заполенения</p>}
                                        </div>
                                 </div>

                                <div className="form-group required ">

                                <label className="control-label" htmlFor="Email">Email</label>

                                     <div className="form-control-wrap">
                                        <input
                                        name="Email"
                                        ref={register({
                                            required: "Required",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "Введите корректный email"
                                            }
                                        })}
                                        />
                                        {errors.Email && <p>Это поле обязательно для заполенения</p>}
                                      </div>
                            </div>

                            <div className="form-group required ">
                                <label className="control-label" htmlFor="Phone">Телефон</label>
                                <div className="form-control-wrap">
                                <input className="form-control"
                                    name="Phone"
                                    ref={register({
                                        required: "Required",
                                        pattern: {
                                            value: /[7-9]{1}[0-9]{9}/i,
                                            message: "Введите корректный номер телефона"
                                        }
                                    })}
                                    />
                                    {errors.Phone && <p>Это поле обязательно для заполенения. Введите корректно</p>}
                                </div>
                                </div>

                            <div className="form-group required ">
                                    <label className="control-label" htmlFor="Message">Хәбәр тексты</label>
                                <div className="form-control-wrap">
                                    <textarea className="form-control"  rows="15"
                                        name="Message"
                                        ref={register({
                                             min: 18, max: 500 })
                                        }
                                    />
                                    {errors.Message && <p>Это поле обязательно для заполенения</p>}
                                </div>
                            </div>
                            <div className="form-group">
                                    <div className="form-btn-wrap form-control-wrap">
                                    <button className="btn btn-primary btn-lg" type="submit"> Отправить </button>
                                </div>
                            </div>
                        </form>

                        <p className="text">

                                Языгыз безгә <a href="mailto:help@tatkniga.ru">help@tatkniga.ru</a>
                        </p>

                        </div>
                    </div>

                    <div className="5">
                        <img src='/img/sendmessage'/>

                    </div>


                </div>
            </div>
        </div>
    );
}

    