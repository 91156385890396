import React from 'react';
import SearchBar from '../../components/Sidebar/SearchBar';
import SidebarCategory from '../../components/Sidebar/SidebarCategory';
import CategoryGrid from '../../components/Category/CategoryGrid';
import BookItems from '../../components/BooksGrid/BookItems';
import PropTypes from 'prop-types';
import OverflowWrapper from 'react-overflow-wrapper';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

import OwlCarousel from 'react-owl-carousel-loop';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import BookItemLoader from '../../components/BooksGrid/BookItemLoader';
class StorePub extends React.Component {

    constructor(props) {
        super(props);
        this.state = { Books: [], loading: true };

    }
    componentDidMount() {
        this.bookData();
        
    }




    static renderBook(Books) {
        return (
            <div className="content-block">
                <div className="block-wrap">
                    <div className="block">
                        <div className="tab-wrap module type-book grid">
                            <div className="tab search">

                                <OwlCarousel
                                    className="owl-theme"
                                    loop
                                    margin={0}
                                    autoWidth={true}
                                    nav
                                    dots={false}

                                    autoplay={true}
                                    autoHeight={true}  /* This is the option enabled */
                                >
                                    {Books.results.map((results, idx) =>

                                        <BookItems key={idx} id={results.id} owl="owlwidth" results={results} />


                                    )}
                                </OwlCarousel>

                          </div>
                    </div>
                </div>
            </div>
            </div>


        )
    };



    render() {
        let contents = this.state.loading
            ? <OwlCarousel
                className="owl-theme"
                loop
                margin={10}
                nav
                autoWidth={true}
                autoplay={true}
                dots={false}
                autoHeight={true}  /* This is the option enabled */
            >

                <BookItemLoader />
                <BookItemLoader />
                <BookItemLoader />
                <BookItemLoader />
                <BookItemLoader />
                <BookItemLoader />
                <BookItemLoader />
                <BookItemLoader />

            </OwlCarousel>
            : StorePub.renderBook(this.state.Books);

        return (
            <div>

                <p></p>
                {contents}
            </div>
        );
    }



    async bookData() {



        const response = await fetch('https://tatkniga.ru/api/StoreBooks?genreId=21');

        const data = await response.json();
        


        this.setState({ Books: data, loading: false });


    }
}

export default StorePub;













