import React, { Component, useContext, useState, useEffect } from 'react';


import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import authService from '../../components/api-authorization/AuthorizeService';
import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';



const MyOrders = () => {

    const [orders, setOrders] = useState({ "data": [{ "id": "-", "dateTime":"-", "address": "-", "phone": "-", "amount": "-", "orderStatus": { "nameRus": "-" } }] })
    const [loadStatus, setLoadStatus]=useState("not")

    useEffect(() => {


        if (loadStatus=="not" ){
            getMyOrders()
            
            setLoadStatus("yes")
        }


    });

    async function getMyOrders(){
        const token = await authService.getAccessToken();
        const response = await fetch('https://tatkniga.ru/api/GetMyOrders', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        setOrders(data);
        
        
    };


    



    return (
        <div className="container">
                <div className="ordertable">
                {orders != "null" &&
                    <table className="table">
                        <thead className="thead">
                            <tr>

                                <th scope="col">Номер заказа</th>

                                <th scope="col">Телефон</th>
                                <th scope="col">Статус</th>
                            <th scope="col">Бәя</th>
                                <th scope="col">#</th>
                            </tr>
                        </thead>

                        <tbody>
                            {orders.data.map(item =>


                                <tr key={item.id}>
                                    <td>Заказ № {item.id} от {item.dateTime}</td>

                                    <td>{item.phone}</td>
                                    <td>{item.orderStatus.nameRus}</td>
                                    <td>{item.amount}</td>

                                    {item.id != "-" &&

                                        <td><Nav.Link as={Link} className="btn" to={`/orderdetails/${item.id}`} > Тәфсил </Nav.Link> </td>
                                    }
                                    {item.id == "-" &&
                                        <td>- </td>
                                    }
                                </tr>
                            )}

                        </tbody>
                    </table>


                }
                

                {orders == "null" &&
                    <p>Сездә заказлар юк</p>
                }

                </div>
          
       

            </div>


        
        )
}

export default MyOrders;