
import React from 'react';
import StoreChild from '../../components/BooksGrid/StoreChild';
import StorePub from '../../components/BooksGrid/StorePub';


import StorePoetry from '../../components/BooksGrid/StorePoetry';


import SideBarList from '../../components/Sidebar/SideBarList';

import StoreCatList from '../../components/Category/StoreCatList';
import { Helmet } from "react-helmet";
const Store = () =>{


        return (
          <>
                <Helmet>
                    <title>Татарское книжное издательство - онлайн магазин книг на татарском и русском языке</title>
                    <meta name="description" content="Онлайн магазин книг на татарском и русском языке. Купить художественные книги, учебники на татарском и русском языке" />
                </Helmet>



                    <div className="main-content no-borders home-page">

                    <div className="block-wrap padding-zero">
                    
                        <div className="block">
                            <StoreCatList />

                        </div>
                    </div>
                    <div className="content-block">
                        <div className="block-wrap padding-zero ">
                            <div className="block-header">
                                <h2 className="titleHead">Балалар әдәбияты</h2>
                            </div>
                            <div className="block ">
                            <StoreChild />

                            </div>
                        </div>
                    </div>
                    <div className="content-block">
                    <div className="block-wrap  ">
                        <div className="block-header">
                                <h2 className="titleHead">Поэзия</h2>
                        </div>
                        <div className="block ">
                            <StorePoetry />

                        </div>
                    </div>
                    </div>

                    <div className="content-block">
                    <div className="block-wrap  ">
                        
                        <div className="block-header">
                            <h2 className="titleHead">Публицистика</h2>
                        </div>
                        <div className="block ">
                            <StorePub />

                            </div>
                        </div>

                    </div>

                  
                        
                    </div>

      </>
            
        );
    
}
export default Store;